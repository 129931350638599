var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MySubscribers"},[_c('Spacer',{attrs:{"num":"1"}}),_c('Title',{class:{ xpadding: _vm.$isMobile || _vm.$isTablet },attrs:{"label":_vm.$locale['my_subscribers']}}),_c('Spacer',{attrs:{"num":"1"}}),_c('div',{class:("statistics-block __general " + (_vm.$loading ? '__loading' : '__loaded'))},[_c('div',{staticClass:"ReportInfo"},[_vm._l((_vm.items),function(subscription,index){return _c('LargeButton',{key:index,staticClass:"MySubscribersCard",attrs:{"label":("<span third-bold>@" + (subscription.user.user) + "</span>"),"desc":("<div><div date>" + (_vm.$locale['activated']) + " " + (_vm.date_locale(subscription.activated)) + "</div></div>"),"UserAvatar":subscription.user,"iconSize":"48px","rightDesc":("" + (subscription.isActive ? "<go-emoji name='heart'></go-emoji>" : "<go-emoji name='heart_broken'></go-emoji>"))},on:{"click":function($event){_vm.view({
            title: _vm.$locale['subscriber'],
            component: function () { return import('@/components/creator/MySubscriber.vue'); },
            class: "menu bottom",
            closeOutside: true,
            animation: 'bottomIn',
            user: subscription.user,
            subscription: subscription,
          })}}})}),_c('LoadMore',{attrs:{"morepage":_vm.statistics.hasMore},on:{"more":function($event){return _vm.updateFeed({ page: _vm.statistics.page + 1 })}}}),(!_vm.$loading && !_vm.statistics.total)?_c('CardMessage',{attrs:{"message":_vm.$locale['no_ubscribers_message']}}):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }