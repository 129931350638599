<template>
  <div class="MySubscribers">
    <Spacer num="1" />
    <Title :label="$locale['my_subscribers']" :class="{ xpadding: $isMobile || $isTablet }" />
    <Spacer num="1" />
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          class="MySubscribersCard"
          v-for="(subscription, index) in items"
          :key="index"
          :label="`<span third-bold>@${subscription.user.user}</span>`"
          :desc="`<div><div date>${$locale['activated']} ${date_locale(subscription.activated)}</div></div>`"
          :UserAvatar="subscription.user"
          :iconSize="`48px`"
          :rightDesc="`${subscription.isActive ? `<go-emoji name='heart'></go-emoji>` : `<go-emoji name='heart_broken'></go-emoji>`}`"
          @click="
            view({
              title: $locale['subscriber'],
              component: () => import('@/components/creator/MySubscriber.vue'),
              class: `menu bottom`,
              closeOutside: true,
              animation: 'bottomIn',
              user: subscription.user,
              subscription: subscription,
            })
          "
        />
        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.total" :message="$locale['no_ubscribers_message']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/creator/subscribers`,
      storeId: "my_subscribers",
      items: [],
      statistics: { total: 0, page: 1 },
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.MySubscribers {
  .MySubscribersCard .LargeButtonDesc {
    opacity: 1;
  }

  .MySubscribersCard .LargeButtonRightDesc go-emoji {
    align-self: flex-end;
  }

  .MySubscribersCard [date] {
    opacity: 0.5;
  }

  .MySubscribersCard [warn] {
    background-color: var(--warn-color);
    color: #fff;
    margin: $mpadding/2 0 0 0;
    display: inline-block;
  }
}
</style>
